// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.ck-content .image-style-side {
  max-width: 50%;
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
.apexcharts-menu-item.exportSVG {
  display: none !important;
}
.jodit-dialog__box .jodit-dialog {
  max-height: 80% !important;
  max-width: 80% !important;
}
.jodit-dialog__box .jodit-dialog__header {
  overflow: initial !important;
}
.jodit-dialog__box .jodit-dialog__content {
  flex: 1 !important;
}
.def-text {
  color: #fff;
  text-align: center;
  font-size: 12px;
  p {
    margin: 0;
  }
}
.def-text-black {
  text-align: center;
  font-size: 12px;
  p {
    margin: 0;
  }
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 2px 5px;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.42rem;
  width: 100%;
  border-radius: 0;
  margin-bottom: 2px;
}
.form-check.form-check-custom .form-check-label {
  margin-left: 0.95rem;
  margin-bottom: 0.95rem;
}

.DowloadButton {
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-edoc {
  width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-table {
  thead {
    th {
      background-color: rgb(70, 65, 65) !important;
      width: 120px;
      height: 75px;
    }
  }
}

tbody {
  tr {
    // height: 120px;
  }
}
.table-fixed {
  overflow: scroll !important;
  thead {
    position: sticky;
    top: 0;
  }
}
.table-cm {
  height: 48em;
  td {
    p {
      margin: 0;
    }
  }
  td:nth-child(4) {
    p {
      white-space: nowrap;
    }
  }
  td:nth-child(5) {
    div {
      width: 120px !important;
    }
  }
  td:nth-child(6) {
    div {
      width: 150px !important;
    }
  }
}

// .table-fixed col {
//   width: 25%;
// }

// .table-fixed tbody {
//   display: block;
//   height: 40em;
//   overflow-y: overlay;
// }

// .table-fixed tr {
//   display: block;
// }

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  // float: left;
  color: white !important;
  white-space: nowrap;
}

.align-self-end {
  margin-top: 0px;
  margin-bottom: 10px;
}

.group-btn-search {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;

  .btn {
    margin-right: 5px;
  }
}

.paginationBttns {
  width: 100%;
  height: 20px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  min-width: 32px;
  height: 32px;
  padding: 5px 12px;
  margin: 0 3px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.43;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: #000;
  }
}

.paginationActive a {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000;
}

.row-table {
  height: 8px !important;
}

.modal .modal-header .close span {
  display: block !important;
}

.btnOk{
  background-color: rgb(48, 133, 214) !important;
  color: white !important;
}

.btnCancel{
  background-color: rgb(221, 51, 51) !important;
  color: white !important;
}

// .textField-input {
//   position: relative;
//   width: 150px; height: 20px;
//   color: white;
// }

// .textField-input:before {
//   position: absolute;
//   top: 3px; left: 3px;
//   content: attr(data-date);
//   display: inline-block;
//   color: black;
// }

// .textField-input::-webkit-datetime-edit, .textField-input::-webkit-inner-spin-button, .textField-input::-webkit-clear-button {
//   display: none;
// }

// .textField-input::-webkit-calendar-picker-indicator {
//   position: absolute;
//   top: 3px;
//   right: 0;
//   color: black;
//   opacity: 1;
// }

.field-date1{
  height: 38px;
  width: 100%;
  background-color: #F4F6F9;
  border-radius: 5px;
  border-width: 0;
}

.field-date2{
  height: 38px;
  width: 185px;
  background-color: #F4F6F9;
  border-radius: 5px;
  border-width: 0;
}
